// 快訊輪播
'use client';
import { useState, useEffect } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Pagination } from 'swiper/modules';
import flashClient from '@/common/apiClient/flashClient';
import FlashSwiperComponent from './FlashSwiperComponent'; // 快訊輪播元件
import chunk from 'lodash/chunk';

import '../../styles/swipers.scss'; // 輪播樣式
import FlashSkeleton from './FlashSkeleton'; // loading 元件

const FlashSwiper = ({ locale }) => {
    const [loading, setLoading] = useState(true);
    // 快訊列表
    const [flashList, setFlashList] = useState([]);

    // 取得快訊列表
    const getFlashList = async () => {
        try {
            const { records } = await flashClient.getFlashList(locale, 0, 12, 1);
            if (records && records.length) {
                setFlashList(chunk(records, 3));
                setLoading(false);
            }
        } catch (e) {
            console.error(e);
        }
    };

    useEffect(() => {
        getFlashList();
    }, []);

    return loading ? (
        // 快訊列表 loading 元件
        <FlashSkeleton />
    ) : (
        <Swiper
            id="flash-swiper"
            className="w-full overflow-hidden !mt-[2px]"
            loop={true}
            pagination={{ clickable: true }}
            modules={[Autoplay, Pagination]}
            spaceBetween={0}
            slidesPerView={1}
            autoplay={{
                delay: 5000,
            }}
        >
            {flashList.map((f) => (
                <SwiperSlide
                    key={f?.[0].title}
                    className="pb-[15px] [&_a]:py-[14px] [&_a]:flex [&_a]:flex-col [&_a]:items-start [&_a]:gap-y-[8px] uxxl:[&_a]:gap-y-[6px] text-btn1 text-black9"
                >
                    {new Array(3).fill(0).map((_, i) => (
                        // 快訊輪播元件
                        <FlashSwiperComponent
                            key={'index' + i + 'flash'}
                            flash={f?.[i]}
                            index={i}
                        />
                    ))}
                </SwiperSlide>
            ))}
        </Swiper>
    );
};

export default FlashSwiper;
