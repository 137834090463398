// 首頁 banner 輪播
'use client';
import { useSelector } from 'react-redux';
import { popState } from '@/slices/popSlice';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Pagination } from 'swiper/modules';
import IndexBanner from './IndexBanner'; // 新聞 banner

import '../../styles/swipers.scss';
import BannerSkelton from '../skelton/BannerSkelton';

const BannerSwiper = ({ carouselNews, newsList, category, loading }) => {
    const { indexMarginTop } = useSelector(popState);

    return (
        <section
            id="index-banner-container"
            className={`relative max-w-[1072px] mx-auto w-full overflow-hidden !py-[26px] ulb:py-[15px] ulg:!py-0 ulg:w-[calc(100vw+1px)]
            bxl:!px-[25px] blb:!px-[25px] ulg:!px-0 ulg:left-[50%] ulg:translate-x-[-50%] ${
                indexMarginTop > 0 ? 'act' : ''
            }`}
        >
            {category === 'Hot' ? (
                <Swiper
                    id="index-banner-swiper"
                    className="w-full overflow-hidden"
                    loop={true}
                    pagination={{ clickable: true }}
                    modules={[Autoplay, Pagination]}
                    spaceBetween={-1}
                    slidesPerView={1}
                    autoplay={{
                        delay: 4000,
                    }}
                    breakpoints={{
                        1024: {
                            spaceBetween: 40,
                        },
                    }}
                >
                    {carouselNews?.map((banner, i) => (
                        <SwiperSlide key={banner.id + 'index-banner' + i}>
                            {/* 新聞 banner */}
                            <IndexBanner banner={banner} />
                        </SwiperSlide>
                    ))}
                </Swiper>
            ) : loading ? (
                // loading 畫面
                <BannerSkelton />
            ) : (
                // 其他類別釘選新聞
                <IndexBanner banner={newsList?.[category]?.[0]} />
            )}
        </section>
    );
};

export default BannerSwiper;
