// 首頁側邊欄 快訊 loading 元件

const FlashSkeleton = () => {
    return (
        <div className="!mt-[2px] w-full [&>*]:space-y-[8px] uxxl:[&>*]:space-y-[6px] relative h-[290px] uxxl:h-[284px]">
            {new Array(3).fill(0).map((_, i) => (
                <div
                    key={`index-aside-flash-${i}-${i}`}
                    className={`w-full py-[14px] ${
                        i < 2 ? 'border-b-1 border-dotted border-black4 border-opacity-[.7]' : ''
                    }`}
                >
                    <p className="w-full skelton h-[33.5px]" />
                    <p className="w-[80px] h-[21.5px] skelton" />
                </div>
            ))}
        </div>
    );
};

export default FlashSkeleton;
